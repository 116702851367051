import { schema } from 'normalizr'
import { Record, List } from 'immutable'

export const CategorySchema = new schema.Entity('categories')

export const CategoryRecord = Record({
  id: undefined,
  name: undefined,
  original_name: undefined,
  description: undefined,
  color: undefined,
  tags: List(),
  visibility: undefined,
  archived_at: undefined,
})

export const emptyCategory = new CategoryRecord()
