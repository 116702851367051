import { getInitialState, buildHandlers } from './lib'
import { createReducer } from 'redux-immutablejs'
import additionalHandlers from './additionalHandlers'
import {
  accountActions,
  bookingsActions,
  campaignsActions,
  conversationsActions,
  currenciesActions,
  currentUsersActions,
  eventsActions,
  filesActions,
  hostApplicationsActions,
  invitesActions,
  IPGeolocationActions,
  languagesActions,
  noteActions,
  notificationsActions,
  partnersActions,
  paymentActions,
  paymentMethodActions,
  payoutsActions,
  placesActions,
  playlistsActions,
  pricingsActions,
  referralWalletsActions,
  repliesActions,
  requestsActions,
  reviewsActions,
  schedulesActions,
  stripeAccountsActions,
  tripAdvisorUsersActions,
  usersActions,
  wishlistsActions,
  categoriesActions,
} from './actions'

const actionsCreators = {
  accountActions,
  bookingsActions,
  campaignsActions,
  conversationsActions,
  currenciesActions,
  currentUsersActions,
  eventsActions,
  filesActions,
  hostApplicationsActions,
  invitesActions,
  IPGeolocationActions,
  languagesActions,
  noteActions,
  notificationsActions,
  partnersActions,
  paymentActions,
  paymentMethodActions,
  payoutsActions,
  placesActions,
  playlistsActions,
  pricingsActions,
  referralWalletsActions,
  repliesActions,
  requestsActions,
  reviewsActions,
  schedulesActions,
  stripeAccountsActions,
  tripAdvisorUsersActions,
  usersActions,
  wishlistsActions,
  categoriesActions,
}

const handlers = buildHandlers({ actionsCreators, additionalHandlers })
export default createReducer(getInitialState({ options: { metadata: true } }), handlers)
