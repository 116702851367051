import { createSelector } from 'reselect'
import {
  getEntities,
  getEntity,
  getEntityOffset,
  getEntitySize,
  getEntityTotal,
  isCreating,
  isFetching,
  isUpdating,
  getCreateError,
  getUpdateErrorRegex,
} from './_utils'
import { emptyPlace } from 'redux/schemas/places'
import { getAmenities } from './categories'

export const getPlaces = getEntities('places')
export const getPlace = getEntity('places')

export const getPlaceAmenities = createSelector(getPlace, getAmenities, (place, amenities) => {
  const tagIds = place.get('tags').map((tag) => tag.get('id'))
  return amenities.filter((amenity) => tagIds.includes(amenity.get('id')))
})

export const getPlaceByFormatted = createSelector(
  getPlaces,
  (state, formatted) => ({ state, formatted }),
  (places, { formatted }) => {
    return places.find((place) => place.formatted === formatted, null, emptyPlace)
  },
)

// metadata
export const getPlacesMetadataOffset = getEntityOffset('places')
export const getPlacesMetadataSize = getEntitySize('places')
export const getPlacesMetadataTotal = getEntityTotal('places')

// notifiers
export const creatingPlace = isCreating('/places')
export const fetchingPlaces = isFetching('/places')
export const updatingPlace = (state, id) => isUpdating(`/places/${id}`)(state)
export const fetchingPlace = (state, id) => isFetching(`/places/${id}`)(state)

// errors
export const getCreatePlaceError = getCreateError('/places')
export const getUpdatePlaceError = getUpdateErrorRegex(/\/places/)
