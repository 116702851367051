import moment from 'moment'
import { screenApi } from 'apis'
import { createSelector } from 'reselect'
import { getEntities, isFetching, isUpdating, getObjId, getUpdateError } from './_utils'
import { getOrganization } from './organizations'
import { getPartner } from './partners'
import { getPlaces } from './places' // need not to refer redux/selectors/index, most likely due to circular dependencies
import { getCurrency } from './currencies' // need not to refer redux/selectors/index, most likely due to circular dependencies
import { getEvents } from './events' // need not to refer redux/selectors/index, most likely due to circular dependencies
import { getFile, getFiles } from './files' // need not to refer redux/selectors/index, most likely due to circular dependencies
import { getLanguage } from './languages' // need not to refer redux/selectors/index, most likely due to circular dependencies
import { getHostApplicationMetadata, getHostApplication } from './hostApplications' // need not to refer redux/selectors/index, most likely due to circular dependencies
import { emptyCurrentUser } from 'redux/schemas'

export const getCurrentUsers = getEntities('currentUsers')

export const getCurrentUser = createSelector(
  getCurrentUsers,
  (currentUsers) => currentUsers.first() || emptyCurrentUser,
)

// Un-memoized selector because it depends on variable outside the redux store
export const getIsCurrentUserLoggedIn = (state) => !!getCurrentUser(state).id && !!screenApi.getHeader('Authorization')

export const getCurrentUserPartner = createSelector(
  getCurrentUser,
  (state) => state,
  (currentUser, state) => getPartner(state, getObjId(currentUser, 'partner')),
)

export const getCurrentUserOrganization = createSelector(
  getCurrentUserPartner,
  (state) => state,
  (currentUser, state) => getOrganization(state, getObjId(currentUser, 'organization')),
)

export const getCurrentUserCountryISO = createSelector(
  getCurrentUser,
  (currentUser) => currentUser.country_iso || undefined,
)

export const getCurrentUserPlaces = createSelector(getCurrentUser, getPlaces, (currentUser, places) =>
  places
    .filter((place) => getObjId(place, 'user') === getObjId(currentUser, 'id'))
    .sort((a, b) => moment.utc(b.updated_at).diff(moment.utc(a.updated_at)))
    .toList(),
)

export const getCurrentUserEvents = createSelector(getCurrentUser, getEvents, (currentUser, events) =>
  events
    .filter((event) => getObjId(event, 'user') === getObjId(currentUser, 'id'))
    .sort((a, b) => moment.utc(b.created_at).diff(moment.utc(a.created_at)))
    .toList(),
)

export const getCurrentUserPublishedEvents = createSelector(getCurrentUser, getEvents, (currentUser, events) =>
  events
    .filter((event) => getObjId(event, 'user') === getObjId(currentUser, 'id'))
    .filter((event) => !!event.published_at)
    .sort((a, b) => moment.utc(b.created_at).diff(moment.utc(a.created_at)))
    .toList(),
)

export const getCurrentUserBirthDate = createSelector(getCurrentUser, (currentUser) => currentUser.birth_date)

export const getCurrentUserFullName = createSelector(
  getCurrentUser,
  (currentUser) => `${currentUser.firstname} ${currentUser.lastname}`,
)

export const getCurrentUserEmail = createSelector(getCurrentUser, (currentUser) => currentUser.email)

export const getCurrentUserPhone = createSelector(getCurrentUser, (currentUser) => currentUser.phone)

export const getCurrentUserAvatar = createSelector(
  getCurrentUser,
  (state) => state,
  (currentUser, state) => getFile(state, currentUser.avatar),
)

export const getCurrentUserLanguage = createSelector(
  getCurrentUser,
  (state) => state,
  (currentUser, state) => getLanguage(state, currentUser.language),
)

export const getCurrentUserLanguages = createSelector(
  getCurrentUser,
  (state) => state,
  (currentUser, state) => currentUser.languages.map((id) => getLanguage(state, id)),
)

export const getCurrentUserNewsletter = createSelector(getCurrentUser, (currentUser) => currentUser.newsletter)

export const getCurrentUserCurrency = createSelector(
  getCurrentUser,
  (state) => state,
  (currentUser, state) => getCurrency(state, getObjId(currentUser, 'currency')),
)

export const getCurrentUserAccountName = createSelector(getCurrentUser, (currentUser) => {
  const civility = currentUser.civility !== null && currentUser.civility !== 'OTHER'
  return `${civility ? currentUser.civility : ''} ${currentUser.firstname} ${currentUser.lastname}`.trim()
})

export const getCurrentUserPaypalID = createSelector(getCurrentUser, (currentUser) => currentUser.paypal_id)

export const getCurrentUserPayoutType = createSelector(getCurrentUser, (currentUser) => currentUser.payout_gateway)

export const getCurrentUserBillingAddress = createSelector(getCurrentUser, (currentUser) => currentUser.billing_address)

export const getCurrentUserBillingCountry = createSelector(
  getCurrentUser,
  (currentUser) => currentUser.billing_country || currentUser.country,
)

export const getCurrentUserBillingCountryIso = createSelector(
  getCurrentUser,
  (currentUser) => currentUser.billing_country_iso || currentUser.country_iso,
)

export const getCurrentUserPayoutGateway = createSelector(getCurrentUser, (currentUser) => currentUser.payout_gateway)

export const getCurrentUserProperties = createSelector(getCurrentUser, (currentUser, state) => currentUser.properties)

export const getCurrentUserSortedFiles = createSelector(getFiles, getCurrentUser, (files, user) =>
  files
    .filter((x) => x.account_id === (user.account_id || user.account))
    .groupBy((x) => x.uploadcare_id) // remove duplicated uploadcare_ids
    .map((x) => x.first())
    .toList()
    .sort((a, b) => (a.id < b.id ? 1 : -1)),
)

export const getCurrentUserHostApplicationId = createSelector(
  getCurrentUser,
  getHostApplication,
  (currentUser, hostApplication) => currentUser.host_application_id || hostApplication.id,
)

export const getCurrentUserIsHostApplicant = createSelector(
  getCurrentUser,
  getHostApplicationMetadata,
  (user, metadata) => user.host_application_id && metadata.get('status') === 'approved' && !user.isActiveHost,
)

export const getCurrentUserIsNewHost = createSelector(
  getCurrentUser,
  (user) => user.host_application_id && user.isNewHost,
)

export const fetchingCurrentUsers = isFetching('/users/profile')
export const updatingCurrentUsers = isUpdating('/users/profile')

export const updatingCurrentUsersAccount = isUpdating('/users/profile/account')

// errors
export const getUpdateCurrentUserError = getUpdateError('/users/profile')
export const getUpdateCurrentUserAccountError = getUpdateError('/users/profile/account')
