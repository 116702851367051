import { createSelector } from 'reselect'
import { getEntities, getEntity, isFetching, getFetchError } from './_utils'
import { Map, List } from 'immutable'

export const getCategories = getEntities('categories')
export const getCategory = getEntity('categories')
export const fetchingCategories = isFetching('/categories')
export const getCategoriesError = getFetchError('/categories')

const makeCategoryTagsSelector = (categoryName) =>
  createSelector(getEntities('categories'), (categories) => {
    const category = categories.find(({ name }) => name === categoryName) || new Map()
    return category.get('tags', new List()).sortBy((tag) => tag.title)
  })

export const getDiets = makeCategoryTagsSelector('diets')
export const getAmenities = makeCategoryTagsSelector('amenities')
export const getFoods = makeCategoryTagsSelector('foods')
export const getAlcohols = makeCategoryTagsSelector('alcohols')
export const getDrinks = makeCategoryTagsSelector('soft_drinks')

const makeGetMaxRatedCriteria = (selector) =>
  createSelector(
    selector,
    (_, score) => score,
    (criteria, score) => criteria.map((c) => c.set('score', score)),
  )

export const getGuestCriteria = makeCategoryTagsSelector('criteria_left_by_guests')
export const getMaxRatedGuestCriteria = makeGetMaxRatedCriteria(getGuestCriteria)

export const getHostCriteria = makeCategoryTagsSelector('criteria_left_by_hosts')
export const getMaxRatedHostCriteria = makeGetMaxRatedCriteria(getHostCriteria)
