import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { CurrencySchema, LanguageSchema } from 'redux/schemas'

export const currenciesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'currencies' })
export const languagesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'languages' })

export const fetchCurrencies = (query) =>
  currenciesActions.fetch({
    url: '/currencies',
    query,
    schema: { currencies: [CurrencySchema] },
  })

export const fetchLanguages = (query) =>
  languagesActions.fetch({
    url: '/languages',
    query,
    schema: { languages: [LanguageSchema] },
  })
