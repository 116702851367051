import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { CategorySchema } from 'redux/schemas'

export const categoriesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'categories' })

export const fetchCategories = () =>
  categoriesActions.fetch({
    url: '/categories',
    schema: { categories: [CategorySchema] },
  })
